<template>

<com-list class="events" api="events" :block="block" v-on:loaded="onListLoaded">

	<router-link :to="$link($route.path + '/' + item.url)" class="list-item" v-for="(item, index) in list" :key="index">

		<div class="list-item-image" v-bgimage="item.image" />

		<div class="list-item-name">{{ item.name }}</div>

		<div class="list-item-date" v-if="item.date">{{ item.date | formatDate('MMMM D, YYYY') }}</div>
		<div class="list-item-date" v-if="!item.date">On demand</div>

	</router-link>

</com-list>

</template>

<script>

import mixBlock from './mixin.js'

export default {

	mixins: [mixBlock],

	components: {
		'com-list': () => import('./common/List')
	}

}

</script>

<style scoped>

.events >>> .list-content {
	display: grid;
	grid-template-columns: 1fr 1fr 1fr;
	grid-gap: 20px;
}

.is-mobile .events >>> .list-content {
	grid-template-columns: 1fr 1fr;
}

.list-item-image {
	width: 100%;
	height: 218px;
	border-radius: 13px;
	background-color: #eee;
	background-position: 50% 50%;
	background-repeat: no-repeat;
	background-size: cover;
}

.is-mobile .list-item-image {
	height: 90px;
}

.list-item-name {
	font-size: 24px;
	color: #1277d4;
	line-height: 30px;
	margin-top: 10px;
}

.is-mobile .list-item-name {
	font-size: 16px;
	line-height: 20px;
}

.list-item-date {
	color: #1a234c;
	font-size: 16px;
	margin-top: 5px;
}

.is-mobile .list-item-date {
	font-size: 12px;
}

</style>
